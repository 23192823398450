/* Overlay for the modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Content of the modal */
  .modal-content {
    background: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 500px;
    max-height: 80%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Title of the modal */
  .modal-content h2 {
    margin-bottom: 20px;
  }
  
  /* Container for the language items */
  .language-list {
    width: 100%;
    max-height: 70%;
    overflow-y: auto;
  }
  
  /* Container for each language item */
  .item_container {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  /* Container for the language image and text */
  .item_language {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  /* Styling for the language image */
  .img_style{
    width: 40px;
    height: 40px;
    box-shadow: 0px 0px 2px var(--ion-color-dark-tint);
    border-radius: 50%;
  }
  
  /* Container for the text */
  .item_left {
    display: flex;
    flex-direction: column;
    max-width: 180px;
  }
  
  /* Styling for the text */
  .item_text {
    font-size: 16px;
  }
  
  .button-close {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button-close:hover {
    background-color: #0056b3;
  }
  