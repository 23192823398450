

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background: #000000;
    padding: 5px;
  
  }

  .body-container{
    margin-top: 100px;

  }
  
  .icon-flag-top{
    width: 25px;
    height: 25px;
  }
  
  
  
  .header-title {
    color:#FFFFFF;
    font-weight: 500;
    min-width: 180px;
  }
  
  .flag-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 5px;
  }
  
  .icon-style {
    width: 45px;
    height: 45px;
  }
  
  .button-style{
    background-color: transparent;
    border: none;
    width: 50px;
    height: 50px;
  
   
  }

  .button-style-small{
    background-color: transparent;
    border: none;
   
  
   
  }
  
  .img_container{
    display: flex;
    align-items: center;
    justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  position: relative; /* Needed for absolute positioning of the red cross */
  
  
  }
  
  .allergy_list_containter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:5px
  }

  .allergy_list_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  
  
  .allergy_title{
    margin-top: -15px;
    font-weight: 600;
    font-size: x-large;
    width: auto;
  }
  
  .allergy_title_english{
    font-weight: 100;
    margin-top: -15px;
  
  }
  
  .allergy_text{
    transform: translateY(-50%);
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
    font-weight: 400;
  
  }
  
  .allergy_img{
  border-radius: 50%;
  width: 120px;
  height: 120px;
  }
  
  
  #container {
    text-align: center;
  
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
  #container strong {
    font-size: 20px;
    line-height: 26px;
  }
  
  #container p {
    font-size: 16px;
    line-height: 22px;
  
    color: #8c8c8c;
  
    margin: 0;
  }
  
  #container a {
    text-decoration: none;
  }
  
  
  
  .img_container:before, .img_container:after {
    content: "";
    position: absolute;
    background-color: red; /* Color of the cross lines */
    width: 100%; /* Width of the cross lines */
    height: 2px; /* Height of the cross lines */
    top: 50%; /* Centering the lines vertically */
    left: 0;
  }
  
  .img_container:before {
    transform: rotate(45deg); /* Angle for the first line */
  }
  
  .img_container:after {
    transform: rotate(-45deg); /* Angle for the second line */
  }
  
  .button_bottom_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    background: #FFFFFF;
    border-radius: 15px;
    width: fit-content;
    height: 53px;
    margin: auto;

  
  
  
  }
  
  .button_action{
  width: 48px;
  height: 48px;
  background: transparent!important;
  }

  .button_action-small{
    width: 30px;
    height: 30px;
    background: transparent!important;
    }
  
  .button_not_available{
    opacity: 0.5;
  }
  
  
  
  
  
  .button_no_padding {
    --padding-start: 0 !important;
    --padding-end: 0 !important;
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    --background-activated: transparent!important;
    --box-shadow: transparent!important;
  
  }
  
  

.main_translation-content{
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 100px;
}

.main_translation-text{
  font-size:x-large
}